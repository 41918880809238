import React from 'react'
import './clouds.sass'
import cloud1 from '../img/felho1.png'
import cloud2 from '../img/felho2.png'
import cloud3 from '../img/felho3.png'
import cloud4 from '../img/felho4.png'
import cloud5 from '../img/felho5.png'
import cloud6 from '../img/felho6.png'
import cloud7 from '../img/felho7.png'
import cloud8 from '../img/felho8.png'
import cloud9 from '../img/felho9.png'
import cloud10 from '../img/felho10.png'
import cloud11 from '../img/felho11.png'

const Cloud = (props) => (
    <div id="background-wrap">
        <div class="x1">
            <img class="cloud"
                src={cloud1}  alt="cloud1"/>
        </div>

        <div class="x2">
            <img class="cloud"
                src={cloud2} alt="cloud2"/>
        </div>
        <div class="x3">
            <img class="cloud"
                src={cloud3}  alt="cloud3"/>
        </div>
        <div class="x4">
            <img class="cloud"
                src={cloud4}  alt="cloud4"/>
        </div>
        <div class="x5">
            <img class="cloud"
                src={cloud5} alt="cloud5"/>
        </div>
        <div class="x6">
            <img class="cloud"
                src={cloud6} alt="cloud6"/>
        </div>
        <div class="x7">
            <img class="cloud"
                src={cloud7} alt="cloud7"/>
                </div>
        <div class="x8">
            <img class="cloud"
                src={cloud8} alt="cloud8"/>
        </div>
        <div class="x9">
            <img class="cloud"
                src={cloud9} alt="cloud9"/>
        </div>
        <div class="x10">
            <img class="cloud"
                src={cloud10} alt="cloud10"/>
        </div>
        <div class="x11">
            <img class="cloud"
                src={cloud11} alt="cloud11"/>
        </div>
       
        
    </div>
)
export default Cloud