import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import 'react-flags-select/scss/react-flags-select.scss';
import Cloud from '../components/clouds'
import Content, { HTMLContent } from '../components/Content'
import Fade from 'react-reveal/Fade'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

const BlogRoll = loadable(() => pMinDelay(import('../components/BlogRoll'), 200))

export const IndexPageTemplate = ({
  image,
  mainpitch,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content
  return (

    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundColor: 'rgba(88, 151, 227, 0.3)',
          
          backgroundPosition: `center`,
          backgroundAttachment: `fixed`,
          marginBottom: '0em'
        }}
      >
      <Img
            className="full-width-image "
            fluid={!!image.childImageSharp ? image.childImageSharp.fluid : image}
            alt="Malib"
          />
        <div
          style={{
            display: 'flex',
            height: '100vh',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column'
          }}
        >

        </div>
      </div>
      <Cloud />

      <div id="about"
        className=" margin-top-0"
        style={{
          backgroundColor: 'transparent',
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
        }}
      >
        <Fade bottom>
          <Img
            className="mainimage "
            fadeIn={true}
            durationFadeIn={1000}
            loading={'lazy'}
            fluid={!!mainpitch.image.childImageSharp ? mainpitch.image.childImageSharp.fluid : mainpitch.image}
            alt="Malib"
          />
        </Fade>

        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column'
          }}
        >

        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <Fade bottom>
                  <PageContent className="content css-typing" content={content} />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="gallery" className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">

                <BlogRoll />
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  mainpitch: PropTypes.object
}

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = post



  return (
    <Layout>
      <IndexPageTemplate
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        mainpitch={frontmatter.mainpitch}
        content={post.html}
       
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
     {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(
              maxWidth: 1024, 
              quality: 100,
              srcSetBreakpoints: [100, 200, 300, 400, 500, 600, 720, 840,960,1200,1400,1600,2000]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch{
          image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        }
      }
    }
    
        
    }
`
